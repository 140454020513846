import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const exibirDadosMedico = async (hash) => {
  const { data } = await apiInstance.get(
    ENDPOINTS.EXIBIR_DADOS_CADASTRAIS_MEDICO_SOLICITANTE + `/${hash}`
  );
  return data;
};

export const listarEspecialidadeMedico = async () => {
  const { data } = await apiInstance.post(
    ENDPOINTS.LISTAR_ESPECIALIDADES_MEDICO_SOLICITANTE
  );
  return data;
};

export const editarDadosMedico = async (hash, payload) => {
  const { data } = await apiInstance.put(
    ENDPOINTS.EDITAR_DADOS_CADASTRAIS_MEDICO_SOLICITANTE + `/${hash}`,
    payload
  );
  return data;
};

export const visualizarSolicitacao = async (hash) => {
  const { data } = await apiInstance.get(
    ENDPOINTS.VISUALIZAR_SOLICITACAO_MEDICO_SOLICITANTE + `/${hash}`
  );
  return data;
};

export const excluirDadosMedico = async (hash) => {
  const { data } = await apiInstance.get(
    ENDPOINTS.EXCLUIR_DADOS_CADASTRAIS_MEDICO_SOLICITANTE + `/${hash}`
  );
  return data;
};