export const rules = {
    emailRules: [
      (v) => !!v || "Esse campo é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "Ensira um e-mail valido",
    ],
    passwordRules: [(value) => !!value || "Esse campo é obrigatório"],
    nameRules: [(v) => !!v || "Esse campo é obrigatório"],
    genericRules: [(v) => !!v || "Esse campo é obrigatório"],
    selectRules: [(v) => !!v || "Esse campo é obrigatório"],
    multiSelectRules: [(v) => v.length > 0 || "Esse campo é obrigatório"],
    imgRules: [
      (value) =>
        !value || value.size < 2000000 || "Avatar size should be less than 2 MB!",
    ],
  };
  